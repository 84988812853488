import React from 'react'
import Button from '@mui/material/Button'
import wattButtonScss from './SelectButton.module.scss'
import { StyledEngineProvider } from '@mui/material/styles';

type Props = {
  value: number,
  unit?: string,
  onClick?: (value: number) => void
}

class SelectButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value)
    }
  }

  render() {
    return (
      // NOTE: MUIスタイルを上書きするためにinjectFirst属性を付与してラップする
      // https://mui.com/material-ui/guides/interoperability/#css-injection-order
      <StyledEngineProvider injectFirst>
        <Button
          className={wattButtonScss.button}
          fullWidth
          variant={'outlined'}
          onClick={this.onClick}
        ><span className={wattButtonScss.value}>{this.props.value}</span><span className={wattButtonScss.unit}>{this.props.unit}</span></Button>
      </StyledEngineProvider>
    )
  }
}

export default SelectButton
