import React from 'react'
import MicrowaveOvenCard from './MicrowaveOvenCard'
import MyMicrowaveOvenCard from './MyMicrowaveOvenCard'
import MyHeatingTimeCard from './MyHeatingTimeCard'
import ResetButton from './ResetButton'

/**
 * 設定した項目と内容
 */
export type SettingItem = {
  name: string,
  value: number,
}

const setting = ['wattOnDisplay', 'minutesOnDisplay', 'secondsOnDisplay', 'watt'] as const

type State = {
  wattOnDisplay: number,
  minutesOnDisplay: number,
  secondsOnDisplay: number,
  watt: number,
  wattOnDisplayDrawer: boolean,
  minutesOnDisplayDrawer: boolean,
  secondsOnDisplayDrawer: boolean,
  wattDrawer: boolean,
  settings: typeof setting[number][]
}

export const WATTS = [200, 500, 600, 700, 800, 900, 1000, 1200, 1500, 1600]
export const MINUTES = [...Array(10)].map((_, i) => i)
export const SECONDS = [...Array(12)].map((_, i) => i * 5)

class Main extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.updateWattOnDisplay = this.updateWattOnDisplay.bind(this)
    this.updateMinutesOnDisplay = this.updateMinutesOnDisplay.bind(this)
    this.updateSecondsOnDisplay = this.updateSecondsOnDisplay.bind(this)
    this.updateWatt = this.updateWatt.bind(this)
    this.startSetting = this.startSetting.bind(this)
    this.cancelSetting = this.cancelSetting.bind(this)

    this.state = {
      wattOnDisplay: 500,
      minutesOnDisplay: 3,
      secondsOnDisplay: 0,
      watt: 600,
      wattOnDisplayDrawer: false,
      minutesOnDisplayDrawer: false,
      secondsOnDisplayDrawer: false,
      wattDrawer: false,
      settings: []
    }
  }

  sec2Min(seconds: number): { minutes: number, seconds: number} {
    const minutes = Math.floor(seconds / 60);
    // 秒数は整数となるよう四捨五入する
    return { minutes, seconds: Math.round(seconds % 60) }
  }

  render() {
    const j = this.state.wattOnDisplay * (this.state.minutesOnDisplay * 60 + this.state.secondsOnDisplay)
    const time = this.sec2Min(j / this.state.watt)
    return (
      <React.Fragment>
        <MicrowaveOvenCard
          minutes={this.state.minutesOnDisplay}
          seconds={this.state.secondsOnDisplay}
          watt={this.state.wattOnDisplay}
          wattDrawer={this.state.wattOnDisplayDrawer}
          minutesDrawer={this.state.minutesOnDisplayDrawer}
          secondsDrawer={this.state.secondsOnDisplayDrawer}
          onWattChange={this.updateWattOnDisplay}
          onMinutesChange={this.updateMinutesOnDisplay}
          onSecondsChange={this.updateSecondsOnDisplay}
          onWattClose={this.cancelSetting}
          onMinutesClose={this.cancelSetting}
          onSecondsClose={this.cancelSetting} />
        <MyMicrowaveOvenCard
          watt={this.state.watt}
          wattDrawer={this.state.wattDrawer}
          onWattChange={this.updateWatt}
          onWattClose={this.cancelSetting} />
        <MyHeatingTimeCard minutes={time.minutes} seconds={time.seconds}></MyHeatingTimeCard>
        <ResetButton onClick={this.startSetting}></ResetButton>
      </React.Fragment>
    )
  }

  updateWattOnDisplay(watt: number) {
    this.setState({ wattOnDisplay: watt, wattOnDisplayDrawer: false })
  }
  updateMinutesOnDisplay(minutes: number) {
    this.setState({ minutesOnDisplay: minutes, minutesOnDisplayDrawer: false })
  }

  updateSecondsOnDisplay(seconds: number) {
    this.setState({ secondsOnDisplay: seconds, secondsOnDisplayDrawer: false })
  }

  updateWatt(watt: number) {
    this.setState({ watt: watt, wattDrawer: false })
  }

  cancelSetting() {
    this.setState((state) => ({
      settings: [],
      wattOnDisplayDrawer: false,
      minutesOnDisplayDrawer: false,
      secondsOnDisplayDrawer: false,
      wattDrawer: false,
    }))
  }

  /**
   * 表示上のワット数と温め時間、ユーザーの電子レンジのワット数を設定する。
   */
  startSetting() {
    this.setState((state) => ({ settings: ['wattOnDisplay', 'minutesOnDisplay', 'secondsOnDisplay', 'watt'] }))
  }

  nextSetting() {
    this.setState((prevState): State => {
      const _settings = [...prevState.settings]
      const settingName = _settings.slice(0, 1)[0]
      return {
        [`${settingName}Drawer`]: true,
        settings: _settings.slice(1, _settings.length)
      } as any
    })
  }

  componentDidUpdate() {
    if(this.state.settings.length === 0) {
      return
    }
    if (this.state.wattOnDisplayDrawer || this.state.minutesOnDisplayDrawer || this.state.secondsOnDisplayDrawer) {
      return
    }
    this.nextSetting()
  }
}

export default Main
