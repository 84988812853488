import React from 'react'
import Grid from '@mui/material/Grid'
import SelectButton from './SelectButton'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'

type Prop = {
  title?: string,
  values: number[],
  unit: string,
  onSelect: (value: number) => void,
  drawer: boolean,
  onClose: () => void,
}

type State = {
  drawer: boolean,
}

class SelectButtonTemplate extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props)
    this.state = {
      drawer: this.props.drawer,
    }
    this.onSelectButtonClick = this.onSelectButtonClick.bind(this)
  }

  render() {
    // 値ごとのボタンを生成
    const buttons = this.props.values.map((value: number) =>
      <Grid xs={6} item key={value}>
        <SelectButton value={value} unit={this.props.unit} onClick={this.onSelectButtonClick}/>
      </Grid>)

    return (
      <Drawer
        anchor={'bottom'}
        open={this.state.drawer}
        onClose={this.props.onClose}
      >
        <Grid
          container
          padding={1}
          spacing={1}
          pb={3}
        >
          {this._title()}
          {buttons}
        </Grid>
      </Drawer>
    )
  }

  _title() {
    if (!this.props.title) {
      return null
    }
    return (<Grid item xs={12}><Typography variant={'subtitle2'} mt={0.5}>{this.props.title}</Typography></Grid>)
  }

  onSelectButtonClick(watt: number) {
    this.props.onSelect(watt)
  }

  componentDidUpdate( prevProps: Readonly<Prop> ){
    if( prevProps.drawer !== this.props.drawer ){
      this.setState({ drawer : this.props.drawer } );
    }
  }
}

export default SelectButtonTemplate
