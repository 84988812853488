import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CardScss from './Card.module.scss'

type Props = {
  minutes: number,
  seconds: number,
}

class MyHeatingTimeCard extends React.Component<Props> {
    render() {
      return (
        <React.Fragment>
          <Card className={CardScss.card}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>温め時間</Typography>
                <Grid container spacing={3}>
                  <Grid item>
                    <div style={{
                      display: 'flex',
                      alignItems: 'baseline'
                    }}>
                      <Typography sx={{ fontSize: "2.25rem" }}>{this.props.minutes}</Typography>
                      <Typography sx={{ fontSize: "1.5rem" }}>分&nbsp;</Typography>
                      <Typography sx={{ fontSize: "2.25rem" }}>{this.props.seconds}</Typography>
                      <Typography sx={{ fontSize: "1.5rem" }}>秒</Typography>
                    </div>
                  </Grid>
                </Grid>
            </CardContent>
          </Card>
          {/*<SelectButtonTemplate values={WATTS}/>*/}
        </React.Fragment>
      )
    }
}

export default MyHeatingTimeCard
