import React from 'react'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import headerIcon from "../assets/svg/header-icon.svg"
import HeaderScss from './Header.module.scss'

export class Header extends React.Component {
  render() {
    return (
      <AppBar
        position="static"
      >
        <Container
          maxWidth="xl"
        >
          <Toolbar disableGutters>
            <img src={headerIcon} alt="アイコン" className={HeaderScss.icon} />
          </Toolbar>
        </Container>
      </AppBar>
    )
  }
}

export default Header
